<template>
  <div>
    我是移动端首页

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
div {
  font-size: 1rem;
}
</style>
